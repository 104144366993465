import React, { forwardRef, useState, useEffect, useRef } from "react";
import CustomButton from "./Button.js";
import PropTypes from "prop-types";
import styles from "./TopSection.module.css";
import { topSectionCopy } from "./copy.js";

const TopSection = forwardRef(({ className = "" }, ref) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingComplete, setTypingComplete] = useState(false);
  const [nextImageIndex, setNextImageIndex] = useState(null);
  const typingSpeed = 100; // milliseconds per character
  const deleteSpeed = 50; // faster deletion
  const { carouselVideos } = topSectionCopy;
  const typingTimeout = useRef(null);

  // Initialize the display text
  useEffect(() => {
    setDisplayText(`${carouselVideos[0].keyword} with LabKick`);
  }, [carouselVideos]);

  // Handle typing animation
  useEffect(() => {
    const currentKeyword =
      nextImageIndex !== null
        ? `${carouselVideos[nextImageIndex].keyword} with LabKick`
        : `${carouselVideos[currentImageIndex].keyword} with LabKick`;

    if (isDeleting) {
      // Deleting characters
      if (displayText.length > 0) {
        typingTimeout.current = setTimeout(() => {
          setDisplayText((text) => text.substring(0, text.length - 1));
        }, deleteSpeed);
      } else {
        setIsDeleting(false);
        setTypingComplete(false);

        // Once text is fully deleted, update the image index
        if (nextImageIndex !== null) {
          // Start transition
          setIsTransitioning(true);

          setTimeout(() => {
            setCurrentImageIndex(nextImageIndex);
            setNextImageIndex(null);

            // End transition
            setTimeout(() => {
              setIsTransitioning(false);
            }, 150);
          }, 100);
        }
      }
    } else if (!typingComplete) {
      // Typing characters
      if (displayText.length < currentKeyword.length) {
        typingTimeout.current = setTimeout(() => {
          setDisplayText((text) =>
            currentKeyword.substring(0, text.length + 1)
          );
        }, typingSpeed);
      } else {
        setTypingComplete(true);
      }
    }

    return () => {
      if (typingTimeout.current) clearTimeout(typingTimeout.current);
    };
  }, [
    displayText,
    isDeleting,
    typingComplete,
    carouselVideos,
    currentImageIndex,
    nextImageIndex,
  ]);

  // Effect to cycle through images every 5 seconds
  useEffect(() => {
    if (typingComplete) {
      const imageInterval = setTimeout(() => {
        // Calculate next image index
        const nextIndex = (currentImageIndex + 1) % carouselVideos.length;
        setNextImageIndex(nextIndex);

        // Start deleting the current text
        setIsDeleting(true);
      }, 4000); // 4 seconds of stable text before starting cycle

      return () => clearTimeout(imageInterval);
    }
  }, [typingComplete, carouselVideos.length, currentImageIndex]);

  const handleSignUp = () => {
    console.log("Sign up button clicked");
    window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
  };

  const handleLogIn = () => {
    window.open("https://app.labkick.ai", "_blank", "noopener,noreferrer");
  };

  // Get current image data
  const currentImage = carouselVideos[currentImageIndex];

  return (
    <section
      ref={ref}
      className={[styles.frameParent, className].join(" ")}
      aria-label="Main hero section"
    >
      <div className={styles.backgroundImageParent}>
        <div className={styles.backgroundImage} aria-hidden="true"></div>
        <div className={styles.headerWrapper}>
          <header className={styles.header}>
            <img
              className={styles.logoIcon}
              alt="LabKick Logo"
              src="/logo.svg"
            />

            <nav className={styles.signUpLogin} aria-label="Account access">
              <CustomButton
                propBackgroundColor="transparent"
                propHeight="3.125rem"
                propBorderRadius="31px"
                propBackground="linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea)"
                propPadding="8px 13px 8px 18px"
                filledButtonCTA="Sign Up"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleSignUp}
                aria-label="Sign up for an account"
              />
              <CustomButton
                propBackgroundColor="#FF8C00"
                propHeight="3.125rem"
                propBorderRadius="31px"
                propBackground="#FF8C00"
                propPadding="8px 25px"
                filledButtonCTA="Log In"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleLogIn}
                aria-label="Log in to your account"
              />
            </nav>
          </header>

          <div className={styles.sidekickContentWrapper}>
            <div className={styles.backgroundContainer} aria-hidden="true">
              <img alt="" src="/logo_with_lines.svg" />
            </div>
            <div className={styles.introBigContainer}>
              <div className={styles.introBig}>
                <h1 className={styles.meetYourDna}>
                  {topSectionCopy.mainHeading}
                </h1>
              </div>
              <div className={styles.introTextBodyContainer}>
                <b className={styles.introTextBody}>
                  <p className={styles.introText}>
                    <span className={styles.typingText}>{displayText}</span>
                    <span
                      className={`${styles.cursor} ${
                        typingComplete && !isDeleting ? styles.cursorBlink : ""
                      }`}
                    >
                      |
                    </span>
                  </p>
                </b>
              </div>
            </div>
          </div>

          <div
            className={styles.videoSection}
            aria-label="Product feature image"
          >
            <div className={styles.videoPlaceholder}>
              <div
                className={`${styles.heroImagevideo} ${
                  isTransitioning ? styles.transitioning : ""
                }`}
                style={{
                  backgroundImage: `url(${currentImage.thumbnailUrl})`,
                }}
                aria-label={`${currentImage.keyword} feature visualization`}
              />
            </div>
            <div className={styles.customButtonWrapper}>
              <CustomButton
                propBackgroundColor="transparent"
                propHeight="3.125rem"
                propBorderRadius="31px"
                propBackground="linear-gradient(90deg, #ff8c00, #c4002f 50%, #d93dea)"
                propPadding="8px 25px"
                filledButtonCTA="Try LabKick For Free"
                propTextAlign="left"
                propTextDecoration="none"
                onClick={handleLogIn}
                aria-label="Try LabKick For Free"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

TopSection.propTypes = {
  className: PropTypes.string,
};

export default TopSection;
