export const topSectionCopy = {
  mainHeading: "Your AI Bioengineering Assistant",
  introText: "Built to help ambitious bioengineers achieve more.",
  dividerText: {
    part1: "Can you kick it?",
    part2: "Yes, you can. ",
  },
  carouselVideos: [
    {
      keyword: "Summarize Papers",
      thumbnailUrl: "/LK_site_chat_thumb.png",
    },
    {
      keyword: "Create Protocols",
      thumbnailUrl: "/LK_site_create_thumb.png",
    },
    {
      keyword: "Design Primers",
      thumbnailUrl: "/LK_site_clone_thumb.png",
    },
    {
      keyword: "Compare Papers", 
      thumbnailUrl: "/LK_site_chat_thumb.png",
    },
    {
      keyword: "Edit Lab Notebooks",
      thumbnailUrl: "/LK_site_create_thumb.png",
    },
    {
      keyword: "Simulate Digests",
      thumbnailUrl: "/LK_site_clone_thumb.png",
    },
    {
      keyword: "Search For Sequences",
      thumbnailUrl: "/LK_site_chat_thumb.png",
    },
    {
      keyword: "Analyze Your Data",
      thumbnailUrl: "/LK_site_code_thumb.png",
    },
    {
      keyword: "Plan Your Cloning",
      thumbnailUrl: "/LK_site_clone_thumb.png",
    },
    {
      keyword: "Create Plots",
      thumbnailUrl: "/LK_site_code_thumb.png",
    },
    {
      keyword: "Analyze Your DNA",
      thumbnailUrl: "/LK_site_clone_thumb.png",
    },
    {
      keyword: "Predict DNA Synthesis",
      thumbnailUrl: "/LK_site_clone_thumb.png",
    },
  ],
  features: [
    {
      header: "Built to<br />collaborate with you.",
      text: "LabKick responds to chat requests in real time, shares its plans, and learns from your feedback.",
    },
    {
      header: "Integrated with<br />familiar tools.",
      text: "LabKick has access to Primer3, ViennaRNA, and our proprietary analysis and annotation tools.",
    },
    {
      header: "Knows your <br />knowledge base.",
      text: "Can search and read your protocols, papers, and more.",
    },

    {
      header: "@Papers, @Web <br /> @Everything.",
      text: "One tap to add context from the web, 20k+ genes, or literature.",
    },
    {
      header: "Plan cloning<br />in minutes.",
      text: "Generate cloning plans with intepretable errors and warnings.",
    },
    {
      header: "Frontier<br />intelligence.",
      text: "LabKick is powered by purpose-built agents and frontier models.",
    },
  ],
};

export const benefitItemCopy = {
  heading: "Got questions? We're all about answers.",
  faqs: [
    {
      question: "What is LabKick?",
      answer:
        "LabKick is an AI-powered lab notebook that helps you conduct research more efficiently. It combines natural language interaction with scientific tools, letting you draft notebooks, analyze data, and design experiments through chat. Think of it as your AI lab assistant that can understand both research protocols and your data.",
    },
    {
      question: "How do I subscribe to LabKick?",
      answer:
        "Visit <a href='https://app.labkick.ai' target='_blank' rel='noopener noreferrer'>app.labkick.ai</a> and click 'Subscribe' in the bottom left. We offer a free Hobby tier, a Pro tier at $20 per month, and Enterprise plans for larger teams. All new users get a 2-week Pro trial.",
    },
    {
      question: "What cloning and assembly methods are supported?",
      answer:
        "LabKick supports Gibson Assembly, Golden Gate, and Traditional restriction enzyme cloning, from one offs to 384-construct combinatorial assemblies. For each method, we generate complete protocols including primer designs and order forms, PCR conditions, and volume calculations.",
    },
    {
      question: "What scientific tools and integrations does LabKick use?",
      answer:
        "LabKick integrates with essential bioinformatics tools including Primer3 for primer design, ViennaRNA for RNA structure prediction, and our proprietary annotation and sequence analysis tools.",
    },
    {
      question: "What languages does LabKick support?",
      answer:
        "You can chat with LabKick in any language - our AI will respond in the same language you use.",
    },
    {
      question: "How does LabKick handle my sequence data and privacy?",
      answer:
        "Your sequence data and research information are encrypted and stored securely. We never share your sequence data with third parties.",
    },
  ],
};

export const frameComponent2Copy = {
  heading: "Let's get kicking.",
  copyright: "© 2025 LabKick. All rights reserved.",
  socialLinks: {
    twitter: "https://x.com/LabKickAI",
    linkedin: "https://www.linkedin.com/company/labkick/",
  },
  footerLinks: {
    contact: {
      text: "Contact info",
      href: "mailto:info@labkick.ai",
    },
    privacy: {
      text: "Privacy Policy",
      href: "/privacy_policy_labkick.html",
    },
    terms: {
      text: "Terms of Service",
      href: "/terms_of_service_labkick.html",
    },
  },
};

export const paymentTiersCopy = {
  academic: {
    title: "Hobby",
    pricing: {
      price: "FREE",
      subtitle: "per 96-construct design*",
    },
    button: {
      text: "Purchase In Tool",
      url: "https://app.labkick.ai",
    },
    features: [
      "Includes",
      "Pro 2-week free trial",
      "50 premium chats",
      "Unlimited notebook, DNA viewer, and annotation tool usage",
    ],
  },
  professional: {
    title: "Pro",
    pricing: {
      price: "$20",
      subtitle: "per 96-construct design*",
    },
    button: {
      text: "Purchase In Tool",
      url: "https://app.labkick.ai",
    },
    features: [
      "Everything in Hobby, plus",
      "500 premium chats per month",
      "Unlimited usage-based pricing",
      "Advanced A.I. model access",
    ],
  },
  enterprise: {
    title: "Enterprise",
    description: ["Solutions for large teams and labs"],
    button: {
      text: "Contact Us",
      url: "mailto:sales@labkick.ai",
    },
    features: [
      "Everything in Pro, plus",
      "Collaboration and project sharing",
      "LIMS",
      "Project management and robotic automation integrations",
      "Centralized billing and usage reporting",
      "SAML/OIDC SSO",
    ],
  },
};

export const secondSectionCopy = {
  splashStatement:
    "LabKick is built to collaborate with you. Integrated with your favorite tools, LabKick can draft, edit, and analyze your notebooks, sequences, and protocols.",
  actionBoxes: [
    {
      direction: "left",
      header: "Chat",
      body: "Chat with your papers, protocol, genes, code and more to understand and plan your research.",
      image: "/LK_site_chat_thumb.png",
      videoUrl: "https://d2kkwte3i7wr0q.cloudfront.net/LK_site_chat.mp4",
    },
    {
      direction: "right",
      header: "Create",
      body: "Tell LabKick exactly what you want to do. The LabKick agent can draft or edit your notebooks, sequences, or plans based upon your prompt.",
      image: "/LK_site_create_thumb.png",
      videoUrl: "https://d2kkwte3i7wr0q.cloudfront.net/LK_site_create.mp4",
    },
    {
      direction: "right",
      header: "Clone",
      body: "Generate cloning plans for one offs and combinatorial assemblies with intepretable errors and warnings from chat. LabKick uses your favorite tools like Primer3, ViennaRNA, and more.",
      image: "/LK_site_clone_thumb.png",
      videoUrl: "https://d2kkwte3i7wr0q.cloudfront.net/LK_site_clone.mp4",
    },
    {
      direction: "left",
      header: "Code",
      body: "Tell LabKick to analyze and plot your data. LabKick is trained to analyze and visualize data like a scientist.",
      image: "/LK_site_code_thumb.png",
      videoUrl: "https://d2kkwte3i7wr0q.cloudfront.net/LK_site_code.mp4",
    },
  ],
};

export const paymentSectionCopy = {
  heading: "Built for your lab.",
  subheading:
    "Whether you kick it solo or with a big research team, LabKick's ready to assist you.",
};
