import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./ActionBox.module.css";

const ActionBox = ({
  className = "",
  header = "",
  body = "",
  image = "",
  imageAlt = "",
  videoUrl = "",
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);

  const handleImageClick = (e) => {
    e.preventDefault();
    setShowVideo(true);

    // Small delay to ensure DOM is updated before attempting to play
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play().catch((err) => {
          console.error("Error playing the video:", err);
        });
      }
    }, 100);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handleImageClick(event);
    }
  };

  return (
    <article
      className={[styles.actionBoxContainer, className].join(" ")}
      role="region"
      aria-labelledby="action-header"
    >
      <div className={styles.textContent}>
        <h2 id="action-header" className={styles.header}>
          {header}
        </h2>
        <p className={styles.body}>{body}</p>
      </div>

      {image && (
        <figure className={styles.imageWrapper}>
          {showVideo && videoUrl ? (
            <video
              ref={videoRef}
              className={styles.image}
              controls
              autoPlay
              playsInline
              tabIndex="0"
              style={{ width: "100%", height: "auto", display: "block" }}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div className={styles.thumbnailContainer}>
              <a
                href="#"
                onClick={handleImageClick}
                onKeyPress={handleKeyPress}
                className={styles.videoLink}
                role="button"
                tabIndex="0"
                aria-label={`Play ${header} video`}
              >
                <img
                  src={image}
                  alt={imageAlt || `${header} demonstration video thumbnail`}
                  className={styles.image}
                />
                <img
                  className={styles.iconPlayCircle}
                  alt="Play video button"
                  src="/-icon-play-circle.svg"
                />
              </a>
            </div>
          )}
        </figure>
      )}
    </article>
  );
};

ActionBox.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  videoUrl: PropTypes.string,
};

export default ActionBox;
